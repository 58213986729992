<template>
    <div style="width: 100%; height: 100%; padding: 0 15px 15px 15px;">
      <b-section-scroll class="g-bg">
        <div slot="header" class="g-page-title">成绩单详情</div>
        <el-form v-loading="loading" class="g-p-20" ref="form" :model="formData" :rules="formRules" label-position="top">
          <el-row :gutter="10">
            <el-col :span="12">
              <!-- <el-form-item
          label="创建时间"
          prop="created_at"
        >
          <el-date-picker
            style="width: 100%;"
            v-model="formData.created_at"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="请选择创建时间"
          >
          </el-date-picker>
        </el-form-item> -->
            </el-col>
            <el-form-item label="标题: " prop="title">
              <el-input v-model="formData.title" clearable placeholder="输入标题"></el-input>
            </el-form-item>
            <el-col :span="12">
              <el-form-item label="来源: " prop="author">
                <el-input v-model="formData.author" disabled clearable placeholder="输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="外链地址: " prop="url">
                <el-input v-model="formData.url" placeholder="输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="类型: " prop="type" style="width: 90%;">
  
            <el-radio v-model="radio" :label="item.id" v-for="(item, index) in columnData" name="item.id"
              @change="changeClick" :key="index">{{ item.name }}</el-radio>
          </el-form-item>
  
          <el-form-item label="子栏目: " prop="type" style="width: 90%;" v-show="columnChildData.length>0">
            <el-radio v-model="radio" :label="item.id" v-for="(item, index) in columnChildData" name="item.id"
              @change="changeClick2" :key="index">{{ item.name }}</el-radio>
          </el-form-item>
  
          <el-form-item label="附件: " prop="images">
            <c-upload-media v-model="formData.media_keys" :default="defaultMedia"></c-upload-media>
          </el-form-item>
          <el-form-item label="图片: " prop="images">
            <c-upload-image v-model="formData.images"></c-upload-image>
          </el-form-item>
          <el-form-item label="内容: " prop="content">
            <editor-textbus v-model="formData.content"></editor-textbus>
          </el-form-item>
          <div class="g-center">
            <b-btn-confirm :loading="submitLoading" @click="onSubmit"></b-btn-confirm>
          </div>
        </el-form>
      </b-section-scroll>
    </div>
  </template>
  
  <script>
    import CUploadImage from '@/components/upload/image'
    import EditorTextbus from '@/components/editor/textbus'
    import CUploadMedia from '@/components/upload/media'
    import {
      getNewsDetail,
      createNews,
      updateNews,
      getPostsColumn
    } from '@/api/news'
    export default {
      components: {
        EditorTextbus,
        CUploadImage,
        CUploadMedia
      },
      data() {
        return {
          radio: '',
          id: '',
          loading: false,
          submitLoading: false,
          formData: {},
          formRules: {
            title: [{
              required: true,
              message: '请输入标题',
              trigger: 'blur'
            }],
            content: [{
              required: true,
              message: '请输入内容',
              trigger: 'blur'
            }],
          },
          columnData: [],
          resColumns: [],
          columnChildData: [],
          mapID: {},
          defaultMedia: [],
        }
      },
      watch: {
  
        '$route.path': {
          handler(path) {
            if (path === '/news/worksUpdate') {
              this.id = this.$route.query.id
              this.fetchData()
            } else {
              this.id = null
              this.formData = {
                title: '',
                content: '',
                images: [],
                category_id: 2,
                author: this.$store.getters['user/deptName'],
                // created_at: this.$tools.dayjs().format('YYYY-MM-DD'),
                url: '',
                column_ids: [],
              }
              this.onGetPostsColumn()
            }
          },
          immediate: true,
        },
      },
      methods: {
        fetchData() {
          this.loading = true
          getNewsDetail(this.id)
            .then(res => {
                console.log(res)
              this.formData = {
                title: res.title,
                content: res.content,
                images: res.images,
                category_id: res.category_id,
                author: res.author,
                // created_at: res.created_at,
                url: res.url,
              }
              this.resColumns = res.columns
              
              this.onGetPostsColumn(res.columns)
            })
            .finally(() => {
              this.loading = false
            })
        },
        fetchUpdate() {
  
  
          this.submitLoading = true
          updateNews(this.id, this.formData)
            .then(res => {
              this.$message.success('成功修改成绩单')
              this.$router.back()
            })
            .finally(() => {
              this.submitLoading = false
            })
        },
        fetchCreate() {
          this.submitLoading = true
          createNews(this.formData)
            .then(res => {
              this.$message.success('成功发布成绩单')
              this.$router.back()
            })
            .finally(() => {
              this.submitLoading = false
            })
        },
        onSubmit() {
          if (this.radio == '') {
            alert('请选择一个类型,比如学习类、业务类等')
            return;
          }
          this.$refs.form.validate(valid => {
            if (valid) {
              if (this.id) {
                this.fetchUpdate()
              } else {
                this.fetchCreate()
              }
            }
          })
        },
  
        onGetPostsColumn(arrData = []) {
  
          this.submitLoading = true
          getPostsColumn(0)
            .then(res => {
  
              var column = res
              column.forEach((item) => {
                //item.checked = false
                arrData.forEach((item1) => {
                  if (item.id === item1.id) {
                    this.radio = item.id
                  }
                })
  
                this.columnData.push(item)
              })
            })
            .finally(() => {
              this.submitLoading = false
            })
        },
        changeClick2(id) {
            this.formData.column_ids=[id]
        },
        changeClick(id) {
           this.formData.column_ids=[id]
          var that = this
  
          this.columnChildData = []
          getPostsColumn(id)
            .then(res => {
              var column = res
              column.forEach((item) => {
                //item.checked = false
                that.resColumns.forEach((item1) => {
                  if (item.id === item1.id) {
                    that.radio = item.id
                  }
                })
  
                if (item.name == '内容置顶' && that.formData.author == '机关党委') {
                  that.columnChildData.push(item)
                } else if (item.name == '内容置顶') {
                  return;
                } else {
                  that.columnChildData.push(item)
                }
  
              })
            })
            .finally(() => {
              this.submitLoading = false
            })
          return
  
  
          // this.formData.column_ids = []
          // this.columnData.forEach((item) => {
          //   if (item.checked) {
          //     debugger
          //     this.formData.column_ids=[this.radio]
          //   }
          // })
        },
      },
    }
  </script>
  